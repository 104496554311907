<template>
  <Banner v-if="data" :text="data.alert.text" />
  <div>
    <Header v-if="data" :title=data.general.titleAffiche :subtitle=data.general.subtitle :location=data.general.location
      :logo=data.general.logo.url :background=data.general.background.url :openingdays=data.general.openingdays
      :urlGoogleMaps=data.general.urlGoogleMaps :urlInstagram=data.general.urlInstagram :urlWaze=data.general.urlWaze
      :urlMenu=data.menu.menuRestaurant.url is_loaded />
    <Header v-else="data" />

    <div class="mx-auto max-w-7xl px-6 lg:px-8 pt-10">
      <p v-if="data" class="text-xl md:text-3xl font-semibold text-justify text-[#24533c] border-b border-t py-6">
        {{ data.general.description }}
      </p>
      <div v-else="data" class="animate-pulse flex space-x-4 border-b border-t">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-2 bg-[#24533c] rounded"></div>
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-[#24533c] rounded col-span-1"></div>
            <div class="h-2 bg-[#24533c] rounded col-span-2"></div>
          </div>
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-[#24533c] rounded col-span-2"></div>
            <div class="h-2 bg-[#24533c] rounded col-span-1"></div>
          </div>
          <div class="h-2 bg-[#24533c] rounded"></div>
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-[#24533c] rounded col-span-2"></div>
          </div>
        </div>
      </div>
    </div>


    <div id="restaurant" class="bg-white pt-6 overflow-hidden">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-16">
        <h2 class="text-4xl text-gray-800 font-extrabold mx-auto md:text-5xl">
          <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#24533c] to-[#3BAE7B]">
            LE RESTAURANT
          </span>
        </h2>
        <p v-if="data" class="text-base mt-2 font-semibold text-gray-600">
          {{ data.restaurantSection.description }}
        </p>
        <a v-if="data" :href="data.menu.menuRestaurant.url">
          <div
            class="w-full mt-4 text-white bg-gradient-to-l from-[#24533c] to-[#3BAE7B] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            Voir le menu du restaurant
          </div>
        </a>
        <a v-if="data" :href="data.menu.menuBurgers.url">
          <div
            class="w-full mt-0 text-white bg-gradient-to-l from-[#e1823e] to-[#eab676] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            Voir le menu burgers à emporter du dimanche soir
          </div>
        </a>
        <div v-else="data" class="animate-pulse flex space-x-4 mt-6">
          <div class="flex-1 space-y-6 py-1">
            <div class="h-2 bg-gray-600 rounded"></div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-1"></div>
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
              <div class="h-2 bg-gray-600 rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-gray-600 rounded"></div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
            </div>
          </div>
        </div>
        <div class="h-[35rem] mt-2 overflow-hidden">
          <div v-if="data" class="h-[35rem] columns-3">
            <div class="h-full overflow-hidden rounded-lg">
              <img :src=data.restaurantSection.photo1.url class="h-full w-full object-cover object-center" />
            </div>
            <div class="h-full overflow-hidden">
              <div class="h-1/2 overflow-hidden rounded-lg">
                <img :src=data.restaurantSection.photo2.url class="h-full w-full object-cover object-center" />
              </div>
              <div class="h-1/2 mt-4 w-full overflow-hidden rounded-lg">
                <img :src=data.restaurantSection.photo3.url class="h-full w-full object-cover object-center" />
              </div>
            </div>
            <div class="h-full overflow-hidden rounded-lg">
              <img :src=data.restaurantSection.photo4.url class="h-full w-full object-cover object-center" />
            </div>
          </div>
          <div v-else="data" class="h-96 columns-2">
            <div class="h-full overflow-hidden rounded-lg">
              <div class="h-full w-full object-cover object-center bg-gray-300" />
            </div>
            <div class="h-full overflow-hidden rounded-lg">
              <div class="h-full w-full object-cover object-center bg-gray-300" />
            </div>
          </div>
        </div>
      </div>


      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 lg:py-16">
        <!-- <div class="mx-auto max-w-2xl py-8 lg:max-w-none">
          <h2 class="text-4xl font-bold text-white-900">Réservation en ligne</h2>
          <Reservation />
        </div> -->
        <div class="mx-auto max-w-2xl py-8 lg:max-w-none">
          <h2 class="text-2xl font-bold text-white-900">Réservation par téléphone :</h2>
          <a v-if="data" :href="'tel:' + (data.restaurantSection.phone.replace(/\s+/g, ''))">
            <div type="submit"
              class="flex w-full justify-center rounded-md bg-indigo-600 mt-6 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {{ data.restaurantSection.phone }}
            </div>
          </a>
        </div>

      </div>
    </div>


    <div id="salondethe" class="bg-zinc-200 overflow-hidden">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-16">
        <h2 class="text-4xl text-gray-800 font-extrabold mx-auto md:text-5xl">
          <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#24533c] to-[#3BAE7B]">
            LE SALON DE THÉ
          </span>
        </h2>
        <p v-if="data" class="text-base mt-2 font-semibold text-gray-600">
          {{ data.theSection.description }}
        </p>
        <div v-else="data" class="animate-pulse flex space-x-4 mt-6">
          <div class="flex-1 space-y-6 py-1">
            <div class="h-2 bg-gray-600 rounded"></div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-1"></div>
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
              <div class="h-2 bg-gray-600 rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-gray-600 rounded"></div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
            </div>
          </div>
        </div>

        <a v-if="data" :href=data.general.urlInstagram>
          <div
            class="w-full mt-4 text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            Retrouvez les pâtisseries du jour sur Instagram
          </div>
        </a>
        <div class="-pt-6">
          <div v-if="data" class="h-96 pt-4 overflow-hidden">
            <div class="h-96 columns-3">
              <div class="h-full overflow-hidden rounded-lg">
                <img :src="data.theSection.photo1.url" :alt="data.theSection.photo1.alt"
                  class="h-full w-full object-cover object-center" />
              </div>
              <div class="h-full overflow-hidden">
                <div class="h-1/2 overflow-hidden rounded-lg">
                  <img :src="data.theSection.photo2.url" :alt="data.theSection.photo2.alt"
                    class="h-full w-full object-cover object-center" />
                </div>
                <div class="h-1/2 mt-4 w-full overflow-hidden rounded-lg">
                  <img :src="data.theSection.photo3.url" :alt="data.theSection.photo3.alt"
                    class="h-full w-full object-cover object-center" />
                </div>
              </div>
              <div class="h-full overflow-hidden rounded-lg">
                <img :src="data.theSection.photo4.url" :alt="data.theSection.photo4.alt"
                  class="h-full w-full object-cover object-center" />
              </div>
            </div>
          </div>
          <div v-else="data" class="h-96 columns-2">
            <div class="h-full overflow-hidden rounded-lg">
              <div class="h-full w-full object-cover object-center bg-gray-300" />
            </div>
            <div class="h-full overflow-hidden rounded-lg">
              <div class="h-full w-full object-cover object-center bg-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="boutique" class="bg-zinc-200 pt-10 overflow-hidden">
      <div id="boutique" class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-16">
        <h2 class="text-4xl text-gray-800 font-extrabold mx-auto md:text-5xl">
          <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#24533c] to-[#3BAE7B]">
            LA BOUTIQUE
          </span>
        </h2>
        <p v-if="data" class="text-base mt-2 font-semibold text-gray-600">
          {{ data.boutiqueSection.description }}
        </p>
        <div v-else="data" class="animate-pulse flex space-x-4 mt-6">
          <div class="flex-1 space-y-6 py-1">
            <div class="h-2 bg-gray-600 rounded"></div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-1"></div>
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
            </div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
              <div class="h-2 bg-gray-600 rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-gray-600 rounded"></div>
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-600 rounded col-span-2"></div>
            </div>
          </div>
        </div>
        <div class="h-96 pt-4 overflow-hidden">
          <div v-if="data" class="h-96 columns-2">
            <div class="h-full overflow-hidden rounded-lg">
              <img :src=data.boutiqueSection.photo1.url class="h-full w-full object-cover object-center" />
            </div>
            <div class="h-full overflow-hidden rounded-lg">
              <img :src=data.boutiqueSection.photo2.url class="h-full w-full object-cover object-center" />
            </div>
          </div>
          <div v-else="data" class="h-96 columns-2">
            <div class="h-full overflow-hidden rounded-lg">
              <div class="h-full w-full object-cover object-center bg-gray-300" />
            </div>
            <div class="h-full overflow-hidden rounded-lg">
              <div class="h-full w-full object-cover object-center bg-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
          <div class="mb-4">
            <span class="text-3xl text-[#24533c] ">
              <FontAwesomeIcon :icon="faHeart" />
            </span>
          </div>
          <h2 class="text-4xl text-gray-800 font-extrabold mx-auto md:text-5xl">
            <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#24533c] to-[#3BAE7B]">
              Un lieu plein d'amour
            </span>

          </h2>
          <p v-if="data" class="mt-6 text-lg leading-8 text-gray-600 ">
            {{ data.general.descriptionLieu }}
          </p>
          <div v-else="data" class="animate-pulse flex space-x-4 mt-6">
            <div class="flex-1 space-y-6 py-1">
              <div class="h-2 bg-gray-600 rounded"></div>
              <div class="grid grid-cols-3 gap-4">
                <div class="h-2 bg-gray-600 rounded col-span-1"></div>
                <div class="h-2 bg-gray-600 rounded col-span-2"></div>
              </div>
              <div class="grid grid-cols-3 gap-4">
                <div class="h-2 bg-gray-600 rounded col-span-2"></div>
                <div class="h-2 bg-gray-600 rounded col-span-1"></div>
              </div>
              <div class="h-2 bg-gray-600 rounded"></div>
              <div class="grid grid-cols-3 gap-4">
                <div class="h-2 bg-gray-600 rounded col-span-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 lg:py-16">
        <div class="mx-auto max-w-2xl py-8 lg:max-w-none">
          <Carousel v-if="data" :urls=data.general.leLieu />
          <div v-else="data" class="h-96 bg-gray-200 rounded"></div>
        </div>
      </div>
    </div>

    <Footer :links="[
      { name: 'Administrateur', href: 'https://admin.lespritdefamille12.fr' },
      { name: 'Voir le Code Source', href: 'https://github.com/Hareski/vue.js-restaurant' },
    ]" />
  </div>
</template>


<script setup lang="ts">

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'


import { ref, computed, onMounted } from "vue";

import { useHead } from '@vueuse/head'

import { request } from "../src/lib/datocms";
import { toHead } from "vue-datocms";

const nightMode = ref(window.matchMedia("(prefers-color-scheme: dark)").matches)

const data = ref<any>(null)

const toggleDayNight = () => {
  nightMode.value = !nightMode.value;
}

const computedMeta = computed(() => {
  if (!data.value) {
    return {};
  }

  return toHead(data.value.profile.seo, data.value.site.favicon);
})

useHead(computedMeta)

onMounted(async () => {
  data.value = await request({
    query: `
      {
        general {
          description
          id
          location
          openingdays
          subtitle
          title
          titleAffiche
          urlGoogleMaps
          urlInstagram
          urlWaze
          logo {
            url
            alt
          }
          background {
            url
          }
          descriptionLieu
          leLieu {
            url
          }
        }
        menu {
          menuRestaurant {
            url
          }
          menuBurgers {
            url
          }
          menuThe {
            url
          }
        }
        restaurantSection {
          description
          phone
          photo1 {
            url
          }
          photo2 {
            url
          }
          photo3 {
            url
          }
          photo4 {
            url
          }
        }
        theSection {
          description
          photo1 {
            url
          }
          photo2 {
            url
          }
          photo3 {
            url
          }
          photo4 {
            url
          }
        }
        boutiqueSection {
          photo1 {
            url
          }
          photo2 {
            url
          }
          description
        }
        site: _site {
          favicon: faviconMetaTags {
            ...metaTagsFragment
          }
        }
        profile {
          seo: _seoMetaTags {
            ...metaTagsFragment
          }
          name
          description
          profession
          location
          email
          coordinates {
            latitude
            longitude
          }
          photo {
            desktopImage: responsiveImage(imgixParams: { w: 360, h: 540, fit: crop, crop: faces, auto: format }) {
              ...imageFields
            }
            mobileImage: responsiveImage(imgixParams: { w: 192, h: 192, fit: crop, crop: faces, auto: format }) {
              ...imageFields
            }
          }
        }
        theme {
          color
          backgroundImage {
            url(imgixParams: { w: 1440, auto: format })
            responsiveImage(imgixParams: { w: 1440, auto: format }) {
              base64
            }
          }
        }
        socials: allSocials {
          social
          url
        }
        products: allProducts {
          id
          description
          title
          price
          href
          photo {
            alt
            url
          }
        }
        alert {
          text
        }
      }

      fragment metaTagsFragment on Tag {
        attributes
        content
        tag
      }
      fragment imageFields on ResponsiveImage {
        srcSet
        sizes
        src
        width
        height
        alt
        title
        base64
      }
    `,
    variables: {},
    preview: false,
  });
})
</script>